import { createSlice } from '@reduxjs/toolkit';
import api from '../service/api';

const initialState = {
    company: {
        id: 0,
        company_name: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
        created_at: '',
        updated_at: ''
    }
};

const slice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        getCompany(state, action) {
            state.company = action.payload;
        },
    }
});

export const { reducer } = slice;

export const getCompany = (id) => async (dispatch) => {
    const data = await api.get(`company/${id}`).then((res) => res.data).then((res) => res.data).catch(console.log);

    if (data) {
        dispatch(slice.actions.getCompany(data));
    }
};

export default slice;
