import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, Drawer, Link, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReceiptIcon from '@material-ui/icons/Receipt';
import useAuth from '../../hooks/useAuth';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ChatAltIcon from '../../icons/ChatAlt';
import ClipboardListIcon from '../../icons/ClipboardList';
import Plus from '../../icons/Plus';
import Play from '../../icons/Play';
import CheckCircle from '../../icons/CheckCircle';
import Cog from '../../icons/Cog';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { getCompany } from '../../slices/company';
import { useDispatch, useSelector } from 'react-redux';

const sections = [
    {
        title: 'General',
        items: [
            {
                title: 'Overview',
                path: '/dashboard',
                icon: <ChartSquareBarIcon fontSize="small" />
            },
        ]
    },
    {
        title: 'Audits',
        path: 'audits',
        items: [
            {
                title: 'My Audits',
                path: '/my-audits',
                icon: <ChartSquareBarIcon fontSize="small" />
            },
            {
                title: 'Request New Audit',
                path: '/request-new-audit',
                icon: <Plus fontSize="small" />
            }
        ]
    },
    {
        title: 'Monitoring',
        items: [
            {
                title: 'View Domains',
                path: '/my-audits',
                icon: <ClipboardListIcon fontSize="small" />
            },
            {
                title: 'Reports',
                path: '/request-new-audit',
                icon: <ReceiptIcon fontSize="small" />
            }
        ]
    },
    {
        title: 'Tasks',
        items: [
            {
                title: 'View Open Tasks',
                path: '/my-audits',
                icon: <CheckCircle fontSize="small" />
            },
            {
                title: 'Reports',
                path: '/request-new-audit',
                icon: <ReceiptIcon fontSize="small" />
            }
        ]
    },
    {
        title: 'Learning Center',
        items: [
            {
                title: 'Videos',
                path: '/videos',
                icon: <Play fontSize="small" />
            },
            {
                title: 'Help Desk',
                path: '/help-desk',
                icon: <ChatAltIcon fontSize="small" />
            }
        ]
    },
    {
        title: 'Settings',
        items: [
            {
                title: 'Settings',
                path: '/settings',
                icon: <Cog fontSize="small" />
            },
        ]
    }
];

const selectCompanyName = (state) => {
    const { company_name: companyName } = state.company.company;

    return companyName || '';
};

const DashboardSidebar = (props) => {
    const { onMobileClose, openMobile } = props;
    const location = useLocation();
    const { user } = useAuth();
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const dispatch = useDispatch();
    const companyName = useSelector(selectCompanyName);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    useEffect(() => {
        dispatch(getCompany(user.company_id));
    }, [user]);

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Scrollbar options={{ suppressScrollX: true }}>
                <Box
                    sx={{
                        display: {
                            lg: 'none',
                            xs: 'flex'
                        },
                        justifyContent: 'center',
                        p: 2
                    }}
                >
                    <RouterLink to="/">
                        <Logo
                            sx={{
                                height: 40,
                                width: 40
                            }}
                        />
                    </RouterLink>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            backgroundColor: 'background.default',
                            borderRadius: 1,
                            display: 'flex',
                            overflow: 'hidden',
                            p: 2
                        }}
                    >
                        <RouterLink to="/dashboard/account">
                            <Avatar
                                src={user.avatar}
                                sx={{
                                    cursor: 'pointer',
                                    height: 48,
                                    width: 48
                                }}
                            />
                        </RouterLink>
                        <Box sx={{ ml: 2 }}>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {`${user.first_name} ${user.last_name}`}
                            </Typography>
                            {companyName && (

                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    Company:
                                    {' '}
                                    <Link
                                        color="primary"
                                        component={RouterLink}
                                        to="#"
                                    >
                                        {companyName}
                                    </Link>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                    {sections.map((section) => (
                        <NavSection
                            key={section.title}
                            pathname={location.pathname}
                            sx={{
                                '& + &': {
                                    mt: 3
                                }
                            }}
                            {...section}
                        />
                    ))}
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                    <Typography
                        color="textPrimary"
                        variant="subtitle2"
                    >
                        Need Help?
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        Check our docs
                    </Typography>
                    <Button
                        color="primary"
                        component={RouterLink}
                        fullWidth
                        sx={{ mt: 2 }}
                        to="/docs"
                        variant="contained"
                    >
                        Documentation
                    </Button>
                </Box>
            </Scrollbar>
        </Box>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'background.paper',
                        height: 'calc(100% - 64px) !important',
                        top: '64px !Important',
                        width: 280
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onMobileClose}
            open={openMobile}
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    width: 280
                }
            }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default DashboardSidebar;
