import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as chatReducer } from '../slices/chat';
import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as mailReducer } from '../slices/mail';
import { reducer as companyReducer } from '../slices/company';

const rootReducer = combineReducers({
    calendar: calendarReducer,
    chat: chatReducer,
    kanban: kanbanReducer,
    mail: mailReducer,
    company: companyReducer,
});

export default rootReducer;
