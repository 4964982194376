import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../__fakeApi__/authApi';
import api from '../service/api';

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    },
    LOGIN: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null,
    }),
    REGISTER: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
    ...initialState,
    platform: 'JWT',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
    passwordRecovery: () => Promise.resolve(),
    passwordReset: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');

                if (accessToken) {
                    const user = await api
                        .get('user/me')
                        .then((res) => res.data)
                        .then((res) => {
                            const { data } = res;

                            return data;
                        });

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    });
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };

        initialize();
    }, []);

    const login = async (email, password) => {
        const accessToken = await api
            .post('authentication/login', {
                email,
                password,
            })
            .then((res) => res.data)
            .then((res) => {
                const { data } = res;

                return data.token;
            });

        if (!accessToken) {
            return;
        }
        localStorage.setItem('accessToken', accessToken);

        const user = await api
            .get('user/me')
            .then((res) => res.data)
            .then((res) => {
                const { data } = res;

                return data;
            });

        dispatch({
            type: 'LOGIN',
            payload: {
                user: {
                    ...user,
                    avatar: null,
                    plan: 'Standard'
                },
            },
        });
    };

    const logout = async () => {
        localStorage.removeItem('accessToken');
        dispatch({ type: 'LOGOUT' });
    };

    const register = async (email, name, password) => {
        const accessToken = await authApi.register({ email, name, password });
        const user = await authApi.me(accessToken);

        localStorage.setItem('accessToken', accessToken);

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        });
    };

    const passwordRecovery = async (email) => {
        await api.post('authentication/forget-password', { email })
            .then((res) => res.data)
            .then((res) => {
                console.log({ res });
            });
    };

    const passwordReset = (token, email, password, passwordConfirmation) => new Promise((resolve, reject) => {
        api.post('authentication/reset-password', {
            token,
            email,
            password,
            password_confirmation: passwordConfirmation
        })
            .then((res) => res.data).then(resolve).catch(reject);
    });

    return (
        <AuthContext.Provider
            value={{
                ...state,
                platform: 'JWT',
                login,
                logout,
                register,
                passwordRecovery,
                passwordReset,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthContext;
